import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-powers-genesis-to-psychofeedback"></a><h2>Psionic Powers</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Genesis&mdash;Psychofeedback</span></h2>
    <span style={{
      "fontStyle": "italic"
    }}>
    </span>
    <h6><a id="genesis"></a>Genesis</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Shaper 9
    <b>Display:</b> Material
    <b>Manifesting Time:</b> One week (8 hours/day)
    <b>Range:</b> 180 ft.; see text
    <b>Effect:</b> A demiplane coterminous with the Astral Plane,
centered on your
location
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 17, XP
    <p>You create a finite plane with limited access: a demiplane.
Demiplanes
created by this power are very small, very minor planes. This power
works best when manifested while you are on the Astral Plane.
Manifestation of this power creates a local density fluctuation that
precipitates the creation of a demiplane. At first, the fledgling plane
grows in radius at a rate of 1 foot per day to an initial maximum
radius of 180 feet as it rapidly draws substance from the surrounding
astral ectoplasm. Once the new demiplane reaches its maximum size, it
doesn&rsquo;t really stop growing, but its growth rate decreases to
only 1
foot per week (approximately a 50-foot increase in radius per year).
Once your demiplane is created, you can travel to it using <a href="psionicPowersAtoC.html#astral-caravan" style={{
        "color": "rgb(87, 158, 182)"
      }}>astral
caravan</a>, <a href="#plane-shift-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>plane shift</a>, or some other power
or permanent link that you
arrange for separately.</p>
    <p>You determine the environment within the demiplane when you
manifest
genesis, reflecting most any desire you can visualize. You determine
factors such as atmosphere, water, temperature, and the general shape
of the terrain. This power cannot create life (including vegetation),
nor can it create construction (such as buildings, roads, wells,
dungeons, and so forth). You must add these details in some other
fashion if you desire. You can&rsquo;t create lingering psionic effects
with
this power; you have to add those separately, if desired. Similarly,
you can&rsquo;t create a demiplane out of esoteric material, such as
silver
or uranium; you&rsquo;re limited to stone and dirt. You can&rsquo;t
manipulate the
time trait on your demiplane; its time trait is as the Material Plane.
Once your demiplane reaches 180 feet in radius, you can manifest this
power again to gradually add another 180 feet of radius to it, and so
on.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Antigenesis:</span> If
genesis is manifested on the Material Plane,
the power
takes effect and the demiplane begins to grow at the rate noted above,
but it gets no larger than a radius of 1 foot per level. The energies
of the new plane are exactly canceled by the energies of the original
plane, creating a dead spot like a limited cancer on the original
plane. The expanding boundary of the dead spot wipes away all
construction, crumbles natural land forms, and evaporates water,
leaving behind a uniformly level area of inert dust. Living creatures
that pass the boundary of the growing dead spot are not directly
affected, but plants can find no sustenance in the dust of the dead
spot, water-breathing creatures die quickly when water turns to dust,
and mobile animals know enough to leave the area alone. Once the wave
of change passes, no special essence remains in the dead spot, and it
may be colonized naturally over the course of several years by
bacteria, plants, and animals.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 1,000 XP.</p>
    <h6><a id="graft-weapon"></a>Graft Weapon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 3
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 24 hours (D)
    <b>Power Points:</b> 5
    <p>You attach any melee weapon you can use in one hand - mundane,
psionic,
or magical - onto the end of one of your arms. The weapon becomes a
natural extension of your arm, and that hand blends seamlessly into the
shaft, hilt, or head of the weapon. Now that the weapon and you are
one, you gain a +1 competence bonus on all attack and damage rolls
while using the weapon.</p>
    <p>The grafted weapon is considered both a standard weapon and a
natural
weapon for the purpose of effects that distinguish between either
weapon type. For instance, the grafted weapon is treated as a natural
weapon for the purpose of delivering a touch attack with a power in
conjunction with the weapon attack. As with any power (or spell) melee
touch attack made in conjunction with a natural weapon attack, the
touch attack effect is not delivered unless the natural weapon strikes
normally; on a failed attack, the touch power (or spell) is wasted.</p>
    <p>For a psychic warrior under the effect of the <a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the
beast</a> power,
grafting a weapon means that attacks with this hand deal the base
damage of the weapon, instead of the claw upon which a psychic warrior
normally relies.</p>
    <p>While your hand is grafted to a weapon, you lose the use of
that hand
and take a -2 penalty on all skill checks requiring the use of hands.
Powers that temporarily polymorph or metamorph you can ignore the
grafted weapon or alter it normally, at your discretion. If the weapon
takes damage, you take damage as well. If you are healed, so is your
grafted weapon. If your weapon is destroyed, you permanently lose 2
points of Constitution; the ability drain persists until you can
restore your natural anatomy (by means of <a href="psionicPowersQtoW.html#regenerate-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>regenerate</a> or a similar
effect).</p>
    <p>When this power&rsquo;s duration expires, the grafted weapon
falls
to the
ground and your hand returns.</p>
    <h6><a id="grease-psionic"></a>Grease, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Visual and olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels) 
    <p>{`Target or `}<b>{`Area:`}</b>{` One object or a 10-ft. square`}</p>
    <b>Duration:</b> 1 round/level (D)
    <b>Saving Throw:</b> See spell text
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 1
    <p>As the <a href="spellsFtoG.html#grease" style={{
        "color": "rgb(87, 158, 182)"
      }}>grease</a> spell, except as noted
here.</p>
    <p>Sometimes this power is referred to as ectoplasmic sheen.</p>
    <h6><a id="grip-of-iron"></a>Grip of Iron</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 1
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level
    <b>Power Points:</b> 1
    <p>You can improve your chances in a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
as an immediate
action,
gaining a +4 enhancement bonus on your grapple checks.</p>
    <p>You can manifest this power with an instant thought, quickly
enough to
gain the benefit of the power in the current round. Manifesting this
power is an immediate action. You
can manifest this power when it isn&rsquo;t your turn (if you are
grappled).</p>
    <p><b>Augment:</b> For every 4 additional power points you spend,
the enhancement
bonus on your grapple checks increases by 2.</p>
    <h6><a id="hail-of-crystals"></a>Hail of Crystals</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Shaper 5
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Area:</b> 20-ft.-radius burst
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Reflex half
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 9
    <p>A tiny ectoplasmic crystal emanates from your outstretched
hand and
rapidly expands to a 2-foot-diameter ball of crystal as it speeds
toward the location you designate. You can choose to aim this crystal
at a single target or at a specific point in space (a grid
intersection).</p>
    <p>If you aim the crystal at a single target, you must make a
ranged touch
attack to strike the target. Any creature or object struck by the ball
of crystal takes 5d4 points of bludgeoning damage.</p>
    <p>Whether the crystal hits its target, misses, or was aimed at a
point in
space, it explodes upon arrival at the location you designated. Anyone
within 20 feet of the explosion takes 9d4 points of slashing damage
from the thousands of crystal shards that spray forth.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
damage from the explosion of the crystal increases by 1d4 points.</p>
    <h6><a id="hammer"></a>Hammer</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Auditory and material
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Touch
    <b>Duration:</b> 1 round
    <span style={{
      "fontWeight": "bold"
    }}>Saving Throw:</span> None
    <span style={{
      "fontWeight": "bold"
    }}>Power Resistance:</span> Yes
    <b>Power Points:</b> 1
    <p>This power charges your touch with the force of a
sledgehammer. A
successful melee touch attack deals 1d8 points of bludgeoning damage.
This damage is not increased or decreased by your Strength modifier.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 round.</p>
    <h6><a id="hustle"></a>Hustle</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 3, psychic warrior 2
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Effect:</b> 1 extra move action
    <b>Power Points:</b> Egoist 5, psychic warrior 3
    <p>You gain an additional move action in the current round.
Taking a full
round&rsquo;s worth of attacks and then using this power to move away
from
your foe does provoke attacks of opportunity.</p>
    <p>You can manifest this power with an instant thought, quickly
enough to
gain the benefit of the power before you move. Manifesting the power is
a swift action, like manifesting a quickened power, and it counts
toward the normal limit of one quickened power per round. You cannot
manifest this power when it isn&rsquo;t your turn.</p>
    <h6><a id="hypercognition"></a>Hypercognition</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 8
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action or 1 immediate action;
see text
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 15
    <p>You make lightning-fast deductions based on only the slightest
clue,
pattern, or scrap of memory resident in your mind. You can make
reasonable statements about a person, place, or object, seemingly from
very little knowledge. However, your knowledge is in fact the result of
a rigorously logical process that you force your mind to undertake,
digging up and correlating every possible piece of knowledge bearing on
the topic (possibly even extracting echoes of knowledge from the Astral
Plane).</p>
    <p>The nature of the knowledge you gain concerning the subject of
your
analysis might include the answer to a riddle, the way out of a maze,
stray bits of information about a person, legends about a place or an
object, or even a conclusion concerning a dilemma that your conscious
mind is unable to arrive at.</p>
    <p>An Intelligence check may be required to obtain the desired
information. If so, you can manifest hypercognition as an immediate
action prior to making the check and receive a +20 bonus for doing so.</p>
    <h6><a id="id-insinuation"></a>Id Insinuation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. +5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Concentration + 1 round
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>As the <a href="spellsC.html#confusion" style={{
        "color": "rgb(87, 158, 182)"
      }}>confusion</a> spell, except as noted
here.</p>
    <p>Swift tendrils of thought disrupt the unconscious mind of any
one
creature, sapping its might. As long as the manifester remains
concentrating fully on this power, the subject is <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a>, making it
unable to independently determine it will do. Roll on the following
table at the beginning of each of the subject&rsquo;s turns to see what
the
subject does in that round.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>d%</th>
          <th>Behavior</th>
        </tr>
        <tr className="odd-row">
          <td>01&ndash;10</td>
          <td>Attack manifester with melee or ranged weapons
(or move toward manifester if attack is not
possible).</td>
        </tr>
        <tr>
          <td>11&ndash;20</td>
          <td>Act normally.</td>
        </tr>
        <tr className="odd-row">
          <td>21&ndash;50</td>
          <td>Do nothing but babble incoherently.</td>
        </tr>
        <tr>
          <td>51&ndash;70</td>
          <td>Flee from manifester at top possible
speed.</td>
        </tr>
        <tr className="odd-row">
          <td>71&ndash;100</td>
          <td>Attack nearest creature (for this purpose, a
psicrystal counts as part of the subject&rsquo;s self).</td>
        </tr>
      </tbody>
    </table>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1, and the power can affect an additional target.
Any additional target cannot be more than 15 feet from another target
of the power.</p>
    <h6><a id="identify-psionic"></a>Identify, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material and mental
    <b>Manifesting Time:</b> One day
    <b>Range:</b> Touch
    <b>Target:</b> One touched object
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>As the <a href="spellsHtoL.html#identify" style={{
        "color": "rgb(87, 158, 182)"
      }}>identify</a> spell, except as noted
here. </p>
    <p>This power is used to identify the abilities of psionic items.</p>
    <h6><a id="immovability"></a>Immovability</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 4
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Concentration
    <b>Power Points:</b> 7
    <p>You are almost impossible to move. Your weight does not vary;
instead,
you mentally attach yourself to the underlying fabric of the plane.
Thus, you could conceivably anchor yourself in midair. Any creature
attempting to physically move you must succeed on an opposed Strength
check, and you gain a +20 bonus on the check. You can&rsquo;t
voluntarily
move to a new location unless you stop concentrating, which ends the
power.</p>
    <p>You cannot apply your Dexterity bonus to Armor Class; however,
your
anchored body gains <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> 15/-.</p>
    <p>You cannot make physical attacks or perform any other
large-scale
movements (you can make smallscale movements, such as breathing,
turning your head, moving your eyes, talking, and so on). Powers with
the teleportation descriptor, or any telekinetic effect, manifested on
you automatically fail.</p>
    <p><b>Augment:</b> If you spend 8 additional power points, you
can manifest this
power as an immediate action.</p>
    <h6><a id="incarnate"></a>Incarnate</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 2 rounds
    <b>Range:</b> See text
    <p>{`Target, Effect, or `}<b>{`Area:`}</b>{` See text`}</p>
    <b>Duration:</b> Permanent; see text
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 9, XP
    <p>This power makes certain other powers permanent. Depending on
the power
to be affected, you must be of a minimum manifester level and must
expend a number of XP.</p>
    <p>You can make the following powers permanent only in regard to
yourself.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th>Power</th>
          <th>Minimum Manifester Level</th>
          <th>XP
Cost</th>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersAtoC.html#aura-sight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Aura sight</a>
          </td>
          <td>15th
          </td>
          <td>2,000 XP
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersAtoC.html#conceal-thoughts" style={{
              "color": "rgb(87, 158, 182)"
            }}>Conceal thoughts</a></td>
          <td>9th
          </td>
          <td>500 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#danger-sense" style={{
              "color": "rgb(87, 158, 182)"
            }}>Danger sense</a></td>
          <td>13th
          </td>
          <td>1,500 XP
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect psionics</a></td>
          <td>9th
          </td>
          <td>500 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#detect-remote-viewing" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect remote viewing</a></td>
          <td>15th
          </td>
          <td>2,000 XP
          </td>
        </tr>
        <tr>
          <td><a href="psionicPowersDtoF.html#detect-teleportation" style={{
              "color": "rgb(87, 158, 182)"
            }}>Detect teleportation</a></td>
          <td>9th
          </td>
          <td>500 XP
          </td>
        </tr>
        <tr className="odd-row">
          <td><a href="psionicPowersDtoF.html#elfsight" style={{
              "color": "rgb(87, 158, 182)"
            }}>Elfsight</a></td>
          <td>11th
          </td>
          <td>1,000 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="#know-direction-and-location" style={{
              "color": "rgb(87, 158, 182)"
            }}>Know
direction and location</a></td>
          <td style={{
            "verticalAlign": "top"
          }}>9th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>500 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="#my-light" style={{
              "color": "rgb(87, 158, 182)"
            }}>My light</a></td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>9th
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>500
XP
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersDtoF.html#darkvision-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic darkvision</a></td>
          <td style={{
            "verticalAlign": "top"
          }}>13th
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1,500 XP
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}><a href="psionicPowersQtoW.html#tongues-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>Psionic tongues</a></td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>11th
          </td>
          <td style={{
            "verticalAlign": "top",
            "backgroundColor": "rgb(242, 228, 188)"
          }}>1,000
XP</td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }}><a href="psionicPowersQtoW.html#ubiquitous-vision" style={{
              "color": "rgb(87, 158, 182)"
            }}>Ubiquitous vision</a></td>
          <td style={{
            "verticalAlign": "top"
          }}>13th</td>
          <td style={{
            "verticalAlign": "top"
          }}>1,500
XP</td>
        </tr>
      </tbody>
    </table>
    <p>You manifest the desired power and then follow it with the
incarnate
manifestation.</p>
    <h6><a id="inertial-armor"></a>Inertial Armor</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Visual; see text
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 hour/level (D)
    <b>Power Points:</b> 1; see text
    <p>Your mind generates a tangible field of force that provides a
+4 armor
bonus to Armor Class. Unlike mundane armor, inertial armor entails no
armor check penalty or speed reduction. Because inertial armor is
composed of psychokinetic force, <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a> creatures
can&rsquo;t
bypass it
the way they do normal armor.</p>
    <p>Your inertial armor can be invisible or can appear as a
colored glow,
at your option.</p>
    <p>The armor bonus provided by inertial armor does not stack with
the
armor bonus provided by regular armor.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
the armor bonus
to Armor Class increases by 1.</p>
    <h6><a id="inertial-barrier"></a>Inertial Barrier</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Kineticist 4, psychic warrior 4
    <b>Display:</b> Auditory and mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level
    <b>Power Points:</b> 7
    <p>You create a skin-tight psychokinetic barrier around yourself
that
resists blows, cuts, stabs, and slashes, as well as providing some
protection against falling. You gain <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> 5/-. Inertial
barrier also absorbs half the damage you take from any fall.</p>
    <h6><a id="inflict-pain"></a>Inflict Pain</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> 1 round/level
    <b>Saving Throw:</b> Will partial; see text
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>You telepathically stab the mind of your foe, causing horrible
agony.
The subject suffers wracking pain that imposes a -4 penalty on attack
rolls, skill checks, and ability checks. If the target makes its save,
it takes only a -2 penalty.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1, and the power can affect an additional target.
Any additional target cannot be more than 15 feet from another target
of the power.</p>
    <h6><a id="insanity"></a>Insanity</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 7
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standardaction
    <b>Range:</b> Medium (100 ft. + 10 ft./level)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 13
    <p>As the <a href="spellsC.html#confusion" style={{
        "color": "rgb(87, 158, 182)"
      }}>confusion</a> spell, except as noted
here. </p>
    <p>Creatures affected by this power are permanently <a href="abilitiesAndConditions.html#confused" style={{
        "color": "rgb(87, 158, 182)"
      }}>confused</a> and
constantly behave randomly. Roll on the following table at the
beginning the subject&rsquo;s turn each round to see what the subject
does in
that round.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>d%</th>
          <th>Behavior</th>
        </tr>
        <tr className="odd-row">
          <td>01&ndash;10</td>
          <td>Attack manifester with melee or ranged weapons
(or move toward manifester if attack is not
possible).</td>
        </tr>
        <tr>
          <td>11&ndash;20</td>
          <td>Act normally.</td>
        </tr>
        <tr className="odd-row">
          <td>21&ndash;50</td>
          <td>Do nothing but babble incoherently.</td>
        </tr>
        <tr>
          <td>51&ndash;70</td>
          <td>Flee from manifester at top possible
speed.</td>
        </tr>
        <tr className="odd-row">
          <td>71&ndash;100</td>
          <td>Attack nearest creature (for this purpose, a
psicrystal counts as part of the subject&rsquo;s self).</td>
        </tr>
      </tbody>
    </table>
    <p>Only <a href="#psychic-chirurgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic chirurgery</a>, <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a>, and other
similarly
extreme
measures can restore the subject&rsquo;s sanity.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1, and the power can affect an additional target.
Any additional target cannot be more than 15 feet from another target
of the power.</p>
    <h6><a id="intellect-fortress"></a>Intellect Fortress</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> 20 ft.
    <b>Area:</b> 20-ft.-radius spread centered on you
    <b>Duration:</b> 1 round
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>You encase yourself and your allies in a shimmering fortress
of
telekinetic force. All damage from powers and psi-like abilities taken
by subjects inside the area of the intellect fortress, including <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, is halved. This
lowering takes place prior to the
effects of other powers or abilities that lessen damage, such as
      <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage
reduction</a> and evasion.</p>
    <p>Powers that are not subject to power resistance are not
affected by an
intellect fortress.</p>
    <p>You can manifest this power instantly, quickly enough to gain
its
benefits in an emergency. Manifesting the power is an immediate action.
You can use this power even
when it&rsquo;s not your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 round.</p>
    <h6><a id="iron-body-psionic"></a>Iron Body, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Psion/wilder 8
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 15
    <p>As the <a href="spellsHtoL.html#iron-body" style={{
        "color": "rgb(87, 158, 182)"
      }}>iron body</a> spell, except
as noted here. </p>
    <h6><a id="keen-edge-psionic"></a>Keen Edge, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <b>Level:</b> Psion/wilder 3, psychic warrior 3
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Targets:</b> One weapon or fifty projectiles, all of which
must be in
contact with each other at the time of manifestation
    <b>Duration:</b> 10 min./level
    <b>Saving Throw:</b> Will negates (harmless, object)
    <b>Power Resistance:</b> Yes (harmless, object)
    <b>Power Points:</b> 5
    <p>As the <a href="spellsHtoL.html#keen-edge" style={{
        "color": "rgb(87, 158, 182)"
      }}>keen edge</a> spell, except as noted
here.</p>
    <h6><a id="knock-psionic"></a>Knock, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One door, box, or chest with an area of up to 10
sq. ft./level
    <b>Duration:</b> Instantaneous; see spell text
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>As the <a href="spellsHtoL.html#knock" style={{
        "color": "rgb(87, 158, 182)"
      }}>knock</a> spell, except as noted
here.</p>
    <h6><a id="know-direction-and-location"></a>Know Direction and
Location</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 1
    <p>You generally know where you are. This power is useful to
characters
who end up at unfamiliar destinations after teleporting, using a gate,
or traveling to or from other planes of existence. The power reveals
general information about your location as a feeling or presentiment.
The information is usually no more detailed than a summary that locates
you according to a prominent local or regional site. Using this power
also tells you what direction you are facing.</p>
    <p>Using this power prior to making a <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(the planes)
check with <a href="psionicPowersAtoC.html#astral-caravan" style={{
        "color": "rgb(87, 158, 182)"
      }}>astral caravan</a> grants a +2 bonus
on the check.</p>
    <h6><a id="leech-field"></a>Leech Field</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Visual; see text
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal; see text
    <b>Target:</b> You
    <b>Duration:</b> 1 min.
    <b>Power Points:</b> 9
    <p>You raise a field of potentiality that drains the vitality
from powers
that you successfully save against. When you succeed on a saving throw
to negate the effect of a foe&rsquo;s power on you, and the power is
one that
leech field is effective against (see below), your body erupts in a
brief flash of crackling dark energy. You gain 1 power point for every
2 power points your foe spent to manifest the power you just saved
against (to a maximum number of points equal to your manifester level).
You cannot gain power points that would cause you to exceed your normal
daily maximum.</p>
    <p>This power is effective against any power that targets a
single
creature and allows the target a saving throw to negate it, except
those that are delivered by a touch attack or a ranged touch attack
(including a ray).</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
duration increases by 1 minute.</p>
    <h6><a id="levitate-psionic"></a>Levitate, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Nomad 2, psion/wilder 2, psychic warrior 2
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal or close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> You or one willing creature or one object (total
weight up to
100 lb./level)
    <b>Duration:</b> 10 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes (harmless, object)
    <b>Power Points:</b> 3
    <p>As the <a href="spellsHtoL.html#levitate" style={{
        "color": "rgb(87, 158, 182)"
      }}>levitate</a> spell, except as noted
here.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> When a psion,
wilder, or a psychic warrior manifests this
power, the target is the manifester (not a willing creature or an
object).</p>
    <h6><a id="major-creation-psionic"></a>Major Creation, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Psion/wilder 5
    <b>Manifesting Time:</b> 10 minutes
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Duration:</b> See spell text
    <b>Power Points:</b> 9
    <p>As the <a href="spellsMtoO.html#major-creation" style={{
        "color": "rgb(87, 158, 182)"
      }}>major creation</a> spell, except as
noted here.</p>
    <h6><a id="matter-agitation"></a>Matter Agitation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Auditory and material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Area:</b> 2 sq. ft. of surface area of an object or creature
    <b>Duration:</b> Concentration, up to 1 min./level
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 1
    <p>You can excite the structure of a nonpsionic, nonmagical
object,
heating it to the point of combustion over time. The agitation grows
more intense in the second and third rounds after you manifest the
power, as described below.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1st Round:</span> Readily
flammable material (paper, dry grass,
tinder,
torches) ignites. Skin reddens (1 point of damage).</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2nd Round:</span> Wood
smolders and smokes, metal becomes hot to the
touch,
skin blisters (1d4 points of damage), hair smolders, paint shrivels,
water boils.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>3rd and Subsequent Rounds:</span>
Wood ignites, metal scorches (1d4
points of
damage for those holding metallic objects). Skin burns and hair ignites
(1d6 points of damage), lead melts.</p>
    <h6><a id="matter-manipulation"></a>Matter Manipulation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <b>Level:</b> Psion/wilder 8
    <b>Display:</b> Auditory and mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Area:</b> 1 cu. ft./level of inanimate material
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 15, XP
    <p>You can weaken or strengthen the substance of an object or
structure.
You can affect both mundane and magical inanimate material. Weakening
an object&rsquo;s substance decreases its hardness and hit points, and
strengthening it increases its hardness and hit points. You can
increase or decrease an object&rsquo;s hardness by up to 5 from its
original
hardness. When hardness increases, the object (or portion of an object)
gains 3 hit points per inch of thickness for every point of increased
hardness. When hardness decreases, the object (or portion of an object)
loses 2 hit points per inch of thickness for every point of decreased
hardness (to a minimum of 1 hit point per inch of thickness).</p>
    <p>You can&rsquo;t decrease the hardness of an object that
already has
hardness
0.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Working Manipulated Metals:</span>
An object or portion thereof whose
hardness
is decreased or increased is permanently changed. Even hardening
adamantine to 25 is possible. You can also harden or weaken a preforged
weapon, a suit of armor, or some other finished item.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 250 XP for
each point by which the object&rsquo;s hardness is
altered.</p>
    <h6><a id="mental-barrier"></a>Mental Barrier</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 3, psychic warrior 3
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round
    <b>Power Points:</b> 5
    <p>You project a field of improbability around yourself, creating
a
fleeting protective shell. You gain a +4 deflection bonus to Armor
Class.</p>
    <p>You can manifest this power instantly, quickly enough to gain
its
benefits in an emergency. Manifesting the power is an immediate action.
You can use this power even
when it&rsquo;s not your turn; however, you must manifest it prior to
an
opponent&rsquo;s attack roll in order to gain this power&rsquo;s
benefit against
that attack.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. If you spend 4 additional power points, the deflection
bonus to
Armor Class increases by 1.</p>
    <p>2. For every additional power point you spend, this
power&rsquo;s
duration
increases by 1 round.</p>
    <h6><a id="mental-disruption"></a>Mental Disruption</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 10 ft.
    <b>Area:</b> 10-ft.-radius spread centered on you
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>You generate a mental wave of confusion that instantly sweeps
out from
your location. All creatures you designate in the affected area (you
can choose certain creatures to be unaffected) must make a Will save or
become <a href="abilitiesAndConditions.html#dazed" style={{
        "color": "rgb(87, 158, 182)"
      }}>dazed</a> for 1 round.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 2 additional power points you spend, this
power&rsquo;s
save DC
increases by 1.</p>
    <p>2. For every 2 additional power points you spend, this
power&rsquo;s
range
and the radius of its area both increase by 5 feet.</p>
    <h6><a id="metaconcert"></a>Metaconcert</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psychic warrior 5, telepath 5
    <b>Display:</b> Visual; see text
    <b>Manifesting Time:</b> 1 minute
    <b>Range:</b> 20 ft.
    <b>Target:</b> You and up to nine other willing psionic creatures
in range
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 9
    <p>You link your psychic might with other psionic creatures,
creating an
entity more powerful than the sum of its parts.</p>
    <p>When you manifest this power, a number of power points you
designate
flows from each participant into a collective pool. One individual is
chosen as the metaconcert conductor by mutual consent of the other
participants (this is usually the manifester, but doesn&rsquo;t have to
be).
Until the power ends, this conductor directs the efforts of the group.
Misty strands of glowing power link the brows of all the participants
in a complex and shifting pattern.</p>
    <p>All the powers of each participant are known to the mental
entity created with metaconcert (which is under the conductor&rsquo;s
command).
This entity can&rsquo;t take any more actions than a normal individual,
but
it manifests all its powers more effectively. Each participant
contributing to the entity provides a cumulative +1 bonus to save DCs
that apply when manifesting a power or using a psi-like ability.
Likewise, each individual provides a cumulative +1 bonus when the
entity makes its own saving throws in response to powers or psi-like
abilities.</p>
    <p>If the psionic entity takes <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a> from a psionic
attack the
total is divided among all the members as determined by the conductor.</p>
    <p>If the entity manifests a power that has an XP cost, all the
participants pay an equal share (the conductor pays the remainder if
the cost can&rsquo;t be divided evenly).</p>
    <p>Once linked, the participants must remain within a
20-foot-radius area,
and as a group can move at a speed of 10 feet. If a participant moves
outside the 20-foot-radius area occupied by the others (whether
willingly or involuntarily), that individual drops out of the group,
and the power point pool of the metaconcert is instantly recalculated.</p>
    <p>All participants who leave before a metaconcert ends or is
dismissed
reclaim a number of power points equal to the current power point pool
divided by the number of members. If the conductor drops out, the power
ends. That same number of points is removed from the power point pool.</p>
    <p>When a metaconcert ends normally or is dismissed, remaining
power
points in the pool are divided among all the participants (the
conductor receives the remainder if the points can&rsquo;t be divided
evenly).</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 minute.</p>
    <h6><a id="metafaculty"></a>Metafaculty</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 9
    <b>Display:</b> Mental, olfactory, and visual
    <b>Manifesting Time:</b> 1 hour
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous and 1 min./level (D); see text
    <b>Power Points:</b> 17, XP
    <p>You elevate your mind to a near-universal consciousness,
cogitating
countless impressions and predictions involving any creature you have
seen before, whether personally or by means of another power such as <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a>.</p>
    <p>This process gives you an uncannily accurate vision of the
creature&rsquo;s
nature, activities, and whereabouts. When you manifest the power, you
learn the following facts about the creature. </p>
    <ul>
      <li>
        <p>Its name, race, alignment, and character class.</p>
      </li>
      <li>
        <p>A general estimate of its level or Hit Dice: low (5 HD or
lower),
medium (6 to 11 HD), high (12 to 20 HD), very high (21 HD to 40 HD), or
deific (41 HD or higher).</p>
      </li>
      <li>
        <p>Its location (including place of residence, town, country,
world, and
plane of existence).</p>
      </li>
      <li>
        <p>Significant items currently in its possession.</p>
      </li>
      <li>
        <p>Any significant activities or actions the creature has
undertaken in
the previous 8 hours, including details such as locales traveled
through, the names or races of those the creature fought, spells it
cast, items it acquired, and items it left behind (including the
location of those items).</p>
      </li>
      <li>
        <p>A current mental view of the creature, as described in the
remote
viewing power, which you can maintain for up to 1 minute per level.</p>
      </li>
    </ul>
    <p>Metafaculty can defeat spells, powers, and special abilities
such as <a href="psionicPowersQtoW.html#screen" style={{
        "color": "rgb(87, 158, 182)"
      }}>screen</a> or <a href="#mind-blank-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind blank</a>
(or even a <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish</a> spell) that normally
obscure
clairsentience powers. You can attempt a caster level check (DC 6 +
caster level of the creator of the obscuring effect) to defeat these
sorts of otherwise impervious defenses.</p>
    <p>Metafaculty is defeated by epic powers, epic spells, and epic
special
abilities that obscure divinations and clairsentience powers.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 1,000.</p>
    <h6><a id="metamorphosis"></a>Metamorphosis</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 4
    <b>Display:</b> Material and olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D) for a creature; 1 hour/level
(D) for an
object
    <b>Power Points:</b> 7
    <p>You assume the form of a creature of the same type as your
normal form,
or any other type except construct, elemental, outsider, and undead.
The assumed form can have as many Hit Dice as your manifester level, to
a maximum of 15.</p>
    <p>You can&rsquo;t assume a form smaller than Fine, nor can you
assume
an <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>, ectoplasmic, or
gaseous form. You cannot take the form of
any creature that has a template. Your type and subtype (if applicable)
change to match the new form.</p>
    <p>Upon changing, you regain lost hit points as if you had rested
for a
night (though this healing does not restore <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a> and provide
other benefits of resting; and changing back does not heal you
further). If you are slain while under the effect of this power, you
revert to your original form, though you remain <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>. You gain the
Strength, Dexterity, and Constitution scores of the new form but retain
your own Intelligence, Wisdom, and Charisma scores. You also gain all
extraordinary special attacks possessed by the form (such as <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>constrict</a>,
      <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>improved grab</a>, and <a href="monsterTypes.html#poison" style={{
        "color": "rgb(87, 158, 182)"
      }}>poison</a>)
but do not gain the extraordinary special
qualities possessed by the new form (such as <a href="monsterTypes.html#blindsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>blindsense</a>,
      <a href="monsterTypes.html#fast-healing" style={{
        "color": "rgb(87, 158, 182)"
      }}>fast healing</a>, <a href="monsterTypes.html#regeneration" style={{
        "color": "rgb(87, 158, 182)"
      }}>regeneration</a>,
and <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a>)
or any supernatural, psionic, or spell-like
abilities.</p>
    <p>You retain all supernatural and spell-like special attacks and
special
qualities of your normal form, except for those requiring a body part
that the new form does not have, if any. You keep all extraordinary
special attacks and special qualities derived from class levels, but
you lose any benefits of the racial traits of your normal form. If you
have a template, special abilities it provides are likewise not
retained. If the assumed form is capable of speech, you can communicate
normally. You retain any manifesting ability you had in your original
form.</p>
    <p>You acquire the physical qualities of the new form while
retaining your
own mind. Physical qualities include natural size, mundane movement
capabilities (such as burrowing, climbing, walking, swimming, and
flight with wings, to a maximum speed of 120 feet for flying or 60 feet
for nonflying movement), natural armor bonus, natural weapons (such as
claws or a bite), racial bonuses on skill checks, racial bonus feats,
and any anatomical qualities (presence or absence of wings, number of
extremities, and so forth). A body with extra limbs does not allow you
to make more attacks (or more advantageous two-weapon attacks) than
normal.</p>
    <p>You can freely designate the new form&rsquo;s minor physical
qualities (such
as hair color, hair texture, and skin color) within the normal ranges
for a creature of that type. The new form&rsquo;s significant physical
qualities (such as height, weight, and gender) are also under your
control, but they must fall within the norms for the new form&rsquo;s
species. You are effectively disguised as an average member of the new
form&rsquo;s race. If you use this power to create a disguise, you get
a +10
bonus on your <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> check.</p>
    <p>When the change occurs, your equipment either remains worn or
held by
the new form (if it is capable of wearing or holding the item in
question) or melds into the new form and becomes nonfunctional. When
you revert to your normal form, any objects previously melded into the
new form reappear in the same location on your body they previously
occupied and are once again functional. Any new items you wore in the
assumed form and can&rsquo;t wear in your normal form fall off and land
at
your feet; any that you could wear in either form or carry in a body
part common to both forms (mouth, hands, or the like) at the time of
reversion are still held in the same way. Any part of the body or piece
of equipment that is separated from the whole reverts to its normal
form.</p>
    <p>You can also use this power to assume the form of an inanimate
object.
You gain the object&rsquo;s hardness and retain your own hit points.
You can
take the shape of almost any simple object you can think of. If you
attempt to take the form of a complex object, you must make an
appropriate skill check.. If you fail the check, your manifestation of
the power does not succeed. Likewise, you cannot take the form of a
complex mechanical mechanism unless you have some sort of skill
associated with the object. You cannot use this power to assume the
form of a psionic item or a magic item, or any object with a hardness
of 15 or higher. You also cannot take the form of a psionically
animated mechanism or any object formed of ectoplasm.</p>
    <p>As an inanimate object, you lose all mobility. You retain your
normal
senses and your ability to speak. You can manifest a power if you make
a <a href="psionicSkills.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> check (DC 20 +
power level); however, doing so ends the
duration of this power. If you take damage while in the form of an
object, your actual body also takes damage (but the object&rsquo;s
hardness,
if any, protects you).</p>
    <h6><a id="metamorphosis-greater"></a>Metamorphosis, Greater</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 9
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D) for a creature; 1 hour/level
(D) for an
object
    <b>Power Points:</b> 17, XP
    <p>As <a href="#metamorphosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>metamorphosis</a>,
except that this power enables you to assume
the form
of any single nonunique object or creature (of any type) from Fine to
Colossal size. The assumed form cannot have more Hit Dice than your
manifester level (to a maximum of 25 HD). Unlike metamorphosis,
this power allows <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>, ectoplasmic, or
gaseous forms to be
assumed.</p>
    <p>You gain all extraordinary and supernatural abilities (both
special
attacks and special qualities) of the assumed form, but you lose your
own supernatural abilities. You also gain the type of the new form (for
example, dragon or magical beast) in place of your own. The new form
does not disorient you. Parts of your body or pieces of equipment that
are separated from you do not revert to their original form.</p>
    <p>You can become just about anything you are familiar with. You
can
change form once each round as a swift action.</p>
    <p>The change takes place either immediately before your regular
action or
immediately after it, but not during the action.</p>
    <p>If you use this power to create a disguise, you get a +10
bonus on your <a href="skillsAll.html#disguise" style={{
        "color": "rgb(87, 158, 182)"
      }}>Disguise</a> check.</p>
    <p>As an inanimate object, you lose all mobility; however, you
retain your
ability to manifest powers normally.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 200 XP.</p>
    <h6><a id="metaphysical-claw"></a>Metaphysical Claw</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 1
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level
    <b>Power Points:</b> 1
    <p>If you have a claw attack (either from an actual natural
weapon or from
an effect such as <a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a>) or a bite
attack (which could be
a natural bite attack or one you gain by means of the power <a href="psionicPowersAtoC.html#bite-of-the-wolf" style={{
        "color": "rgb(87, 158, 182)"
      }}>bite of the
wolf</a>), you can use this power to provide one of your natural
weapons a
+1 enhancement bonus on attack rolls and damage rolls.</p>
    <p><b>Augment:</b> If you spend 4 additional power points, this
power&rsquo;s duration
increases to 1 hour per level.</p>
    <p>In addition, for every 4 additional power points you spend,
this power
improves the natural weapon&rsquo;s enhancement bonus on attack rolls
and
damage rolls by 1.</p>
    <h6><a id="metaphysical-weapon"></a>Metaphysical Weapon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <b>Level:</b> Psychic warrior 1
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Weapon touched
    <b>Duration:</b> 1 min./level
    <b>Saving Throw:</b> Will negates (harmless, object)
    <b>Power Resistance:</b> Yes (harmless, object)
    <b>Power Points:</b> 1
    <p>Metaphysical weapon gives a weapon a +1 enhancement bonus on
attack
rolls and damage rolls. (An enhancement bonus does not stack with a
masterwork weapon&rsquo;s +1 bonus on attack rolls.)</p>
    <p>Alternatively, you can affect up to fifty arrows, bolts, or
bullets.
The projectiles must be of the same type, and they have to be together
(such as in the same quiver). Projectiles, but not thrown weapons, lose
their enhancement when used. (Treat shuriken as projectiles, rather
than thrown weapons, for the purpose of this power.)</p>
    <p>You can&rsquo;t manifest this power on most natural weapons,
including a
psychic warrior&rsquo;s claw strike. This power does work on a weapon
brought
into being by the <a href="#graft-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>graft weapon</a> power.</p>
    <p><b>Augment:</b> If you spend 4 additional power points, this
power&rsquo;s duration
increases to 1 hour per level.</p>
    <p>In addition, for every 4 additional power points you spend,
this power
improves the weapon&rsquo;s enhancement bonus on attack rolls and
damage
rolls by 1.</p>
    <h6><a id="microcosm"></a>Microcosm</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 9
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <span style={{
      "fontWeight": "bold"
    }}>Target or Area:</span> One
creature; or one or more creatures within a
15-ft.-radius sphere
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 17
    <p>This power enables you to warp the consciousness and senses of
one or
more creatures, sending the victim into a catatonic state. When
microcosm is manifested, you can target either a single creature within
range or a group of creatures all located within the power&rsquo;s area.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Single Target:</span> If
microcosm targets a single creature, that creature&rsquo;s
senses are pinched off from the real world if it currently has 100 or
fewer hit points. The subject&rsquo;s senses are all completely
fabricated
from within its own mind, though it may not realize this. In reality,
the subject sprawls limply, drooling and mewling, and eventually dies
of thirst and starvation without care. The subject lives within its own
made-up world until the time of its actual death.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Area Effect:</span> If
microcosm is manifested on an area, it sends all
affected creatures into a shared catatonia (the world is a construct,
but within the world, the victims can interact with each other). It
affects only creatures that currently have 30 or fewer hit points, and
only up to a total of 300 hit points of such creatures. The power
affects creatures with the lowest hit point totals first. (Creatures
with negative hit points count as having 0 hit points.)</p>
    <p>Manifesting microcosm a second time on an affected creature
turns its
sensory pathways outward once more. Otherwise, only very potent powers
(such as <a href="#psychic-chirurgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic chirurgery</a> or <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a>) or similar
effects
(such as <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a> or <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish</a>)
can undo the mental crosswiring that this
power brings about.</p>
    <p><b>Augment:</b> For every additional power point you spend,
the number of
individual and group hit points the power can affect increases by 10.</p>
    <h6><a id="mind-blank-personal"></a>Mind Blank, Personal</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 7, psychic warrior 6
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> One day
    <b>Power Points:</b> Psion/wilder 13, psychic warrior 11
    <p>As <a href="#mind-blank-psionic" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionic mind blank</a> (see below),
except as noted here.</p>
    <h6><a id="mind-blank-psionic"></a>Mind Blank, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 8
    <b>Display:</b> Olfactory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> One day
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 15
    <p>The subject is protected from all devices, powers, and spells
that
detect, influence, or read emotions or thoughts. This power protects
against powers with the mind-affecting or scrying descriptors. Psionic
mind blank even foils <a href="psionicPowersAtoC.html#bend-reality" style={{
        "color": "rgb(87, 158, 182)"
      }}>bend reality</a>, <a href="spellsHtoL.html#limited-wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>limited
wish</a>, <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a>, <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality
revision</a>, and <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish </a>when they are used in such
a way as to affect the
subject&rsquo;s mind or to gain information about it (however, <a href="#metafaculty" style={{
        "color": "rgb(87, 158, 182)"
      }}>metafaculty</a>
can pierce the protective quality of psionic mind blank). In the case
of <a href="psionicPowersQtoW.html#remote-viewing" style={{
        "color": "rgb(87, 158, 182)"
      }}>remote viewing</a> or <a href="spellsS.html#scrying" style={{
        "color": "rgb(87, 158, 182)"
      }}>scrying</a>
that scans an area the creature is in, the
effect works but the creature simply isn&rsquo;t detected. Remote
viewing
(scrying) attempts that are targeted specifically at the subject do not
work at all.</p>
    <h6><a id="mind-probe"></a>Mind Probe</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Charm)
[Mind-Affecting]</span>
    <b>Level:</b> Telepath 5
    <b>Display:</b> Auditory, material, and visual
    <b>Manifesting Time:</b> 1 minute
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> 1 round/level (D)
    <b>Saving Throw:</b> Will partial; see text
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 9
    <p>All the subject&rsquo;s memories and knowledge are accessible
to
you, from
memories deep below the surface to those still easily called to mind.
You can learn the answer to one question per round, to the best of the
subject&rsquo;s knowledge. If the subject succeeds on a Will save, it
is not
required to answer the question; however, making a save does not end
the power. You can ask the subject a new question (or the same
question) in subsequent rounds for as long as the power&rsquo;s
duration
persists.</p>
    <p>You can probe a sleeping subject and automatically get an
answer to
your question. If the subject then succeeds on a Will save, it wakes
after providing the answer and thereafter can resist answering by
making Will saves as described above.</p>
    <p>Subjects that do not wish to be probed can attempt to move
beyond the
power&rsquo;s range, unless they are somehow hindered. You pose the
questions
telepathically, and the answers to those questions are imparted
directly to your mind. You and the subject do not need to speak the
same language, though less intelligent creatures may yield up only
appropriate visual images in answer to your questions.</p>
    <h6><a id="mind-seed"></a>Mind Seed</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion) [Evil,
Mind-Affecting]</span>
    <b>Level:</b> Telepath 8
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> One touched Medium or smaller humanoid
    <b>Duration:</b> Instantaneous; see text
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 15, XP
    <p>You impress the totality of your psyche into a subject&rsquo;s
subconscious.
If successfully implanted, the seed of your mind
&ldquo;germinates&rdquo; over the
period of one week. During this time, the subject begins to
unconsciously take on your mannerisms. </p>
    <p>When integration is complete (after one week), the subject
becomes you
in mind as you were when you manifested the power, but the
subject&rsquo;s
level is eight lower than your own. (In effect, the subject has
received eight negative levels - but these are negative levels that
can&rsquo;t
be removed.) The subject does not have any of your physical ability
scores or equipment, but does have the Intelligence, Wisdom, and
Charisma scores you had when you were eight levels lower. The subject
also knows the powers you knew when you were eight levels lower.</p>
    <p>While the subject is initially your mental duplicate, the two
personalities diverge over time. Although the subject starts off with
memories of your experiences, it possesses its original
&ldquo;soul&rdquo; and
physical body and is free to develop its own personality based on its
own new experiences. Thus, the subject is not your slave or servant,
but instead a nonplayer character in its own right that shares your
earlier memories.</p>
    <p>Protection from evil or a similar spell or power can prevent
you from
implanting mind seed, or prevent a seed from germinating while the
protective power lasts. Otherwise, a germinating seed can be removed
(prior to germination) only by <a href="#psychic-chirurgery" style={{
        "color": "rgb(87, 158, 182)"
      }}>psychic chirurgery</a>, <a href="psionicPowersQtoW.html#reality-revision" style={{
        "color": "rgb(87, 158, 182)"
      }}>reality revision</a>, or
similarly high-level effects. Manifesting mind seed again during the
germination period also cleanses the subject&rsquo;s mind.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 3,000 XP.</p>
    <h6><a id="mind-switch"></a>Mind Switch</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 6
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 round
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Targets:</b> You and one other creature
    <b>Duration:</b> 10 min./level
    <b>Saving Throw:</b> Will negates; see text
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 11, XP
    <p>You can attempt to take control of a nearby living creature,
forcing
your mind (and soul) into its body, and its mind into your body. You
can target any creature whose Hit Dice are equal to or less than your
manifester level.</p>
    <p>You possess the target&rsquo;s body and force the
creature&rsquo;s mind
into your
body unless it succeeds on a Will save. You can move your mind back
into your own body whenever you desire, which returns the
subject&rsquo;s
mind to its own body and ends the power. If the manifestation succeeds,
your life force occupies the host body, and the host&rsquo;s life force
takes
over yours.</p>
    <p>You can call on rudimentary or instinctive knowledge of the
subject
creature, but not upon its acquired or learned knowledge (such as
skills and feats it possesses). The same is true for the subject in
your body. The mind switch brings about the following changes. </p>
    <ul>
      <li>
        <p>You gain the type of your assumed body.</p>
      </li>
      <li>
        <p>You gain the Strength, Dexterity, and Constitution scores
of your
assumed body.</p>
      </li>
      <li>
        <p>You gain the natural armor, natural attacks, movement, and
other
simple physical characteristics of your assumed body.</p>
      </li>
      <li>
        <p>You gain the extraordinary special attacks and qualities
of your
assumed body, but you do not gain supernatural or spell-like abilities.</p>
      </li>
      <li>
        <p>You gain the possessions and equipment of your assumed
body.</p>
      </li>
      <li>
        <p>You retain your own hit points, saving throws (possibly
modified by
new ability scores), class abilities, supernatural and spell-like
abilities, spells and powers, and skills and feats (although skill
checks use your new ability scores, and you may be temporarily unable
to use feats whose requirements you do not meet in your new body).</p>
      </li>
    </ul>
    <p>Supernatural abilities that require a certain body part may be
unavailable in your new form.</p>
    <p>If either body is killed while the power is in effect, the
other
participant also dies when the power ends. If one participant&rsquo;s
body
becomes <a href="abilitiesAndConditions.html#petrified" style={{
        "color": "rgb(87, 158, 182)"
      }}>petrified</a>, imprisoned by <a href="spellsTtoZ.html#temporal-stasis" style={{
        "color": "rgb(87, 158, 182)"
      }}>temporal stasis</a> or <a href="spellsHtoL.html#imprisonment" style={{
        "color": "rgb(87, 158, 182)"
      }}>imprisonment</a>,
or
incapacitated in some other way, the other participant will be
incapacitated in that way when the power ends.</p>
    <p>A targeted <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a> (or similar
spells or effects)
successfully
manifested on either participant causes both minds to return to their
original bodies.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 100 XP.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1.</p>
    <h6><a id="mind-switch-true"></a>Mind Switch, True</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 9
    <b>Manifesting Time:</b> 1 minute
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates; see text
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 17, XP
    <p>As <a href="#mind-switch" style={{
        "color": "rgb(87, 158, 182)"
      }}>mind
switch</a>, except as noted here. You permanently exchange
bodies
with the subject. Since this power&rsquo;s duration is instantaneous,
you
cannot be forced to return to your natural body by means of <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel
psionics</a>, an <a href="spellsAtoB.html#antimagic-field" style={{
        "color": "rgb(87, 158, 182)"
      }}>antimagic field</a>, or a similar
effect. If the subject&rsquo;s
body dies while you are in it, you are <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a>. The subject immediately
loses one level, but otherwise survives the experience in your natural
body. If your natural body dies while the subject is in it, you
immediately lose one level, but you likewise survive the experience in
your new body.</p>
    <p>Your &ldquo;natural&rdquo; body is always considered to be the
last one
you
switched out of. If you exchange bodies with a chain of multiple
subjects, you need worry only about the welfare of the last body you
switched with. In other words, if your mind is expelled from your
current body, your mind returns to the last body you switched with, not
to your original body. Similarly, if your original body dies but you
have since switched minds with a second subject, you take no penalty.
You lose a level only if the body you inhabited immediately prior to
your current body is killed.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 10,000 XP.</p>
    <h6><a id="mind-thrust"></a>Mind Thrust</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 1
    <p>You instantly deliver a massive assault on the thought
pathways of any
one creature, dealing 1d10 points of damage to it.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
damage increases by 1d10 points. For each extra 2d10 points of damage,
this power&rsquo;s save DC increases by 1.</p>
    <h6><a id="mind-trap"></a>Mind Trap</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 5
    <p>You set up a trap in your mind against psionic intruders.
Anyone who
attacks you with a telepathy power immediately loses 1d6 power points.
This power&rsquo;s effect does not negate the power that is currently
being
used against you. 
    </p>
    <p>You can manifest this power instantly, quickly enough
to gain its benefit in an emergency. Manifesting the power is an
immediate action. You can use
this power even when it is not your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 round.</p>
    <h6><a id="mindlink"></a>Mindlink</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 1
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels); see text
    <b>Targets:</b> You and one other willing creature within range
that has an
Intelligence score of 3 or higher
    <b>Duration:</b> 10 min./level
    <b>Saving Throw:</b> None; see text
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 1
    <p>You forge a telepathic bond with your target. You can
communicate
telepathically through the bond even if you do not share a common
language. No special power or influence is established as a result of
the bond. Once the bond is formed, it works over any distance (although
not from one plane to another).</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. If you spend 4 additional power points, you can attempt to
create a
telepathic bond with a creature that is not willing (Will save negates).</p>
    <p>2. For every additional power point you spend, this power can
affect an
additional target. Any additional target cannot be more than 15 feet
from another target of the power.</p>
    <h6><a id="mindlink-thieving"></a>Mindlink, Thieving</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 4
    <b>Duration:</b> 10 min./level (D)
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>As <a href="#mindlink" style={{
        "color": "rgb(87, 158, 182)"
      }}>mindlink</a>,
except that if the target is a psionic character
or
creature that knows powers, you can temporarily borrow a power of your
choice (you are aware of what powers the subject knows, up to the
highest level of power you can manifest).</p>
    <p>Borrowing the subject&rsquo;s power is a separate standard
action
that
provokes an attack of opportunity. If that attack succeeds, the mental
communication provided by this power ends immediately. The borrowed
power fades from the subject&rsquo;s awareness and appears within your
own.
You can now spend power points to manifest the borrowed power just as
if it were one of your powers known. You maintain knowledge of the
borrowed power until the duration of your thieving mindlink expires, at
which time you lose knowledge of the power and the power reappears in
the mind of the subject, no matter how far from you the subject is.
Even if the subject is slain, you lose knowledge of the borrowed power
when this power&rsquo;s duration expires.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
save DC increases by 1.</p>
    <h6><a id="mindwipe"></a>Mindwipe</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Auditory, mental, and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Fortitude negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>You partially wipe your victim&rsquo;s mind of past
experiences,
bestowing
two <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>negative levels</a> upon it. If the
subject has at least as many
negative levels as Hit Dice, it dies. For each negative level it gains,
a psionic creature loses knowledge of one power from its highest
available level, and a number of power points from its maximum power
point total sufficient to manifest that power. The effects of multiple
negative levels stack.</p>
    <p>If the subject survives, it loses these two negative levels
after 1
hour. (No Fortitude save is necessary to avoid gaining the negative
level permanently.)</p>
    <p><b>Augment:</b> You can manifest this power in one or both of
the following
ways.</p>
    <p>1. For every 2 additional power points you spend, this
power&rsquo;s
save DC
increases by 1.</p>
    <p>2. For every 3 additional power points you spend, this power
bestows an
additional negative level on the subject.</p>
    <h6><a id="minor-creation-psionic"></a>Minor Creation, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity (Creation)</span>
    <b>Level:</b> Shaper 1
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 minute
    <b>Range:</b> 0 ft.
    <b>Effect:</b> Unattended, nonpsionic, nonmagical object of
nonliving plant
matter, up to 1 cu. ft./level
    <b>Duration:</b> 1 hour/level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 1
    <p>As the <a href="spellsMtoO.html#minor-creation" style={{
        "color": "rgb(87, 158, 182)"
      }}>minor creation</a> spell, except as
noted here.</p>
    <h6><a id="missive"></a>Missive</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting,
Language-Dependent]</span>
    <b>Level:</b> Psion/wilder 1
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Effect:</b> Mental message delivered to subject
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 1
    <p>You send a telepathic message of up to ten words to any living
creature
within range. Missive is strictly a one-way exchange from you to the
subject. If you do not share a common language, the subject
&ldquo;hears&rdquo;
meaningless mental syllables.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
range increases by 5 feet.</p>
    <h6><a id="missive-mass"></a>Missive, Mass</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting,
Language-Dependent]</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Long (400 ft. + 40 ft./level)
    <b>Targets:</b> All creatures in a 400 ft. + 40 ft./level radius
centered on
you; see text
    <b>Effect:</b> Mental message delivered to subjects
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 3
    <p>You send a telepathic message of up to twenty-five words to
all
creatures within range. You can include or exclude from this broadcast
any creature you can see, as well as any creature that you know or know
of. Mass missive is strictly a one-way exchange from you to the
subjects. If you do not share a common language, the subjects
&ldquo;hear&rdquo;
meaningless mental syllables.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
range increases by 40 feet and its save DC increases by 1.</p>
    <h6><a id="modify-memory-psionic"></a>Modify Memory, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <b>Level:</b> Telepath 4
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Permanent
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>As the <a href="spellsMtoO.html#modify-memory" style={{
        "color": "rgb(87, 158, 182)"
      }}>modify memory</a> spell, except as
noted here.</p>
    <h6><a id="moment-of-prescience-psionic"></a>Moment of
Prescience, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 7
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level or until discharged
    <b>Power Points:</b> 13
    <p>As the <a href="spellsPtoR.html#moment-of-prescience" style={{
        "color": "rgb(87, 158, 182)"
      }}>moment of prescience</a> spell,
except as noted here.</p>
    <h6><a id="my-light"></a>My Light</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis [Light]</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Effect:</b> 20-ft. cone of light emanating from you
    <b>Duration:</b> 10 min./level (D)
    <b>Power Points:</b> 1
    <p>Your eyes beam forth a 20-foot cone of light. You and other
creatures
can see normally in the light. If you walk into an area filled with
psionic or magical darkness, my light goes dark while you stay within
that area.</p>
    <p>If my light is used in conjunction with <a href="psionicPowersDtoF.html#elfsight" style={{
        "color": "rgb(87, 158, 182)"
      }}>elfsight</a>, the cone of
light
extends out to 40 feet instead of 20 feet.</p>
    <p><b>Augment:</b> If you spend 2 additional power points, you
can manifest this
power as a swift action.</p>
    <h6><a id="null-psionic-field"></a>Null Psionics Field</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychokinesis</span>
    <b>Level:</b> Kineticist 6
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 10 ft.
    <b>Area:</b> 10-ft.-radius emanation centered on you
    <b>Duration:</b> 10 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> See text
    <b>Power Points:</b> 11
    <p>An invisible barrier surrounds you and moves with you. The
space within
this barrier is impervious to most psionic effects, including powers,
psi-like abilities, and supernatural abilities. Likewise, it prevents
the functioning of any psionic items or powers within its confines. A
null psionics field suppresses any power or psionic effect used within,
brought into, or manifested into its area, but does not negate it. Time
spent within a null psionics field counts against a suppressed
effect&rsquo;s
duration.</p>
    <p>Astral constructs and summoned creatures wink out if they
enter a null
psionics field. They reappear in the same spot once the field goes
away. Time spent winked out counts normally against the duration of the
power that is maintaining the construct or summoned creature.</p>
    <p>Creation powers with instantaneous durations and calling
powers are not
affected by a null psionics field because the power itself is no longer
in effect, only its result.</p>
    <p>A normal creature (a normally encountered construct rather
than a
created one, for instance) can enter the area, as can normal missiles.
Furthermore, while a psionic sword does not function psionically within
the area, it is still a sword (and a masterwork sword at that). The
power has no effect on golems and other constructs that are imbued with
magic during their creation process and are thereafter self-supporting
(unless they have been summoned or have a limited duration, in which
case they are treated like any other summoned creatures). Elementals,
corporeal undead, and outsiders are likewise unaffected unless
summoned. These creatures&rsquo; spell-like or supernatural abilities,
however, may be temporarily nullified by the field.</p>
    <p><a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dispel psionics</a> does not remove
the field. Two or more null
psionics
fields sharing any of the same space have no effect on each other.
Certain powers may be unaffected by null psionics field (see the
individual power descriptions). Artifacts and deities are unaffected by
mortal power such as this. Should a creature&rsquo;s space extend
across the
boundary of the area enclosed by the field, any part of the creature
that lies outside the effect is unaffected by the field.</p>
    <h6><a id="oak-body"></a>Oak Body</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psion/wilder 7, psychic warrior 5
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> Psion/wilder 13, psychic warrior 9
    <p>This power transforms your body into living oak, which grants
you
several advantages.</p>
    <p>You gain <a style={{
        "color": "#579eb6"
      }} href="abilitiesAndConditions.html#damage-reduction">damage reduction</a> 10/ slashing
and a +5 bonus to
natural armor
that overlaps (does not stack with) any natural armor bonus you may
already have. You are immune to ability damage, blindness, deafness,
disease, drowning, poison, stunning, and all powers, spells, or attacks
that affect your physiology or respiration, because you have no
physiology or respiration while this power is in effect.</p>
    <p>You take only half damage from cold effects of all kinds.
However, you
become susceptible to all special attacks that affect wood, and you
gain <a href="monsterTypes.html#vulnerability-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>vulnerability to fire</a>.</p>
    <p>You gain a +4 enhancement bonus to Strength, but you take a -2
penalty
to Dexterity (to a minimum Dexterity score of 1), and your speed is
reduced to half normal. You can speak but cannot drink (and thus
can&rsquo;t
use potions) or play wind instruments. You have an armor check penalty
of -4 and an arcane spell failure chance of 25%.</p>
    <p>Your unarmed attacks deal damage equal to a club sized for you
(1d4 for
Small characters, 1d6 for Medium characters), and you are considered
armed when making unarmed attacks. When you make a full attack against
an object or structure using your unarmed strike, you deal double
damage.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
duration increases by 1 minute.</p>
    <h6><a id="object-reading"></a>Object Reading</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 2
    <b>Display:</b> Auditory and material
    <b>Manifesting Time:</b> 1 minute
    <b>Range:</b> Touch
    <b>Target:</b> Object touched
    <b>Duration:</b> Concentration, up to 10 min./level (D)
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 3
    <p>You can learn details of an inanimate object&rsquo;s previous
owner.
Objects
accumulate psychic impressions left by their previous owners, which can
be read by use of this power. The amount of information revealed
depends on how long you study a particular object.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>1st Minute:</span> Last
owner&rsquo;s race.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2nd Minute:</span> Last
owner&rsquo;s gender.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>3rd Minute:</span> Last
owner&rsquo;s age.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>4th Minute:</span> Last
owner&rsquo;s alignment.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>5th Minute:</span> How last
owner gained and lost the object.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>6th+ Minute:</span>
Next-to-last owner&rsquo;s race, and so on.</p>
    <p>The power always correctly identifies the last owner of the
item, and
the original owner (if you keep the power active long enough).</p>
    <p>There is a 90% chance that this power will successfully
identify all
other former owners in sequence, but there is a 10% chance that one
former owner will be skipped and thus not identified.</p>
    <p>This power will not identify casual users as owners. (Anyone
who uses
an object to attack someone or something is not thereafter considered a
casual user.)</p>
    <p>An object without any previous owners reveals no information.
You can
continue to run through a list of previous owners and learn details
about them as long as the power&rsquo;s duration lasts. If you use this
power
additional times on the same object, the information yielded is the
same as if you were using the power on the object for the first time.</p>
    <p><b>Augment:</b> For every additional power point you spend,
this power&rsquo;s
maximum duration increases by 10 minutes.</p>
    <h6><a id="overland-flight-psionic"></a>Overland Flight, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 6
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 hour/level (D)
    <b>Power Points:</b> 11
    <p>As the <a href="spellsMtoO.html#overland-flight" style={{
        "color": "rgb(87, 158, 182)"
      }}>overland flight</a> spell, except as
noted here.</p>
    <h6><a id="painful-strike"></a>Painful Strike</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level
    <b>Power Points:</b> 3
    <p>Your natural weapons cause additional pain. Each successful
attack you
make with a natural weapon deals an extra 1d6 points of nonlethal
damage to the target.</p>
    <p><b>Augment:</b> If you spend 6 additional power points, you
can manifest this
power as a swift action.</p>
    <h6><a id="personality-parasite"></a>Personality Parasite</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Medium (100 ft. + 10 ft./ level)
    <b>Target:</b> One Medium or smaller humanoid
    <b>Duration:</b> 1 round/level (D)
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>You attempt to briefly partition the mind of your foe, calving
off a
minor personality that is antagonistic to the main personality. The
parasitic personality functions with complete autonomy from the main
personality. It does not control the body physically, but it can take
one standard action each round that is purely mental, such as
manifesting a power, in the same turn that the subject takes its normal
actions.</p>
    <p>The parasitic personality manifests powers using the
subject&rsquo;s
power
point reserve and known powers, but can only manifest powers three or
more levels lower than the highest level of power the subject can
normally manifest. </p>
    <p>The parasitic personality actively attempts to manifest powers
that
negatively impact the subject, using the highest-level powers possible
(so as to deplete the subject&rsquo;s power point reserve), and the
most
deadly to the subject. You do not have control over what the parasitic
personality does, though it always works against the interest of the
subject.</p>
    <p>Both minds communicate with each other telepathically. If a
creature is
targeted by a compulsion or charm effect while under the effect of this
power, it can make a second saving throw if the first one fails. If
both saving throws fail, then this power ends and the creature is
affected by the charm or compulsion effect.</p>
    <p>The parasitic personality does not gain any advantages if the
main
personality is subjected to a <a href="spellsHtoL.html#haste" style={{
        "color": "rgb(87, 158, 182)"
      }}>haste</a> or <a href="psionicPowersQtoW.html#schism" style={{
        "color": "rgb(87, 158, 182)"
      }}>schism</a>
effect.</p>
    <h6><a id="phase-door-psionic"></a>Phase Door, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 7
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 0 ft.
    <b>Effect:</b> Ethereal 5-ft. by 8-ft. opening, 10 ft. deep + 5
ft. deep per
three levels
    <b>Duration:</b> One usage per two levels
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 13
    <p>As the <a href="spellsPtoR.html#phase-door" style={{
        "color": "rgb(87, 158, 182)"
      }}>phase door</a> spell, except as
noted here. This power is
subject to <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a>.</p>
    <h6><a id="plane-shift-psionic"></a>Plane Shift, Psionic</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Targets:</b> Willing creature touched, or up to eight willing
creatures
joining hands
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 9
    <p>As the <a href="spellsPtoR.html#plane-shift" style={{
        "color": "rgb(87, 158, 182)"
      }}>plane shift</a> spell, except as
noted here.</p>
    <h6><a id="power-leech"></a>Power Leech</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy (Compulsion)
[Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Visual; see text
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> Any psionic creature
    <b>Duration:</b> Concentration, up to 1 round/level; see text
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>Your brow erupts with an arc of crackling dark energy that
connects
with your foe, draining it of 1d6 power points and adding 1 of those
points to your reserve (unless that gain would cause you to exceed your
maximum). </p>
    <p>The drain continues in each round you maintain concentration
while the
subject of the drain remains in range. If the subject is drained to 0
power points, this power ends.</p>
    <p>Concentrating to maintain power leech is a full-round action
(you can
take no other actions aside from a 5-foot step) instead of a standard
action.</p>
    <h6><a id="power-resistance"></a>Power Resistance</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Creature touched
    <b>Duration:</b> 1 min./level
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 9
    <p>The creature gains power resistance equal to 12 + your
manifester
level. </p>
    <h6><a id="precognition"></a>Precognition</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 1
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 10 min./level
    <b>Power Points:</b> 1
    <p>Precognition allows your mind to glimpse fragments of
potential future
events - what you see will probably happen if no one takes action to
change it. However, your vision is incomplete, and it makes no real
sense until the actual events you glimpsed begin to unfold.
That&rsquo;s when
everything begins to come together, and you can act, if you act
swiftly, on the information you previously received when you manifested
this power. </p>
    <p>In practice, manifesting this power grants you a
&ldquo;precognitive
edge.&rdquo;
Normally, you can have only a single precognitive edge at one time. You
must use your edge within a period of no more than 10 minutes per
level, at which time your preknowledge fades and you lose your edge.</p>
    <p>You can use your precognitive edge in a variety of ways.
Essentially,
the edge translates into a +2 insight bonus that you can apply at any
time to either an attack roll, a damage roll, a saving throw, or a
skill check. You can elect to apply the bonus to the roll after you
determine that your unmodified roll is lower than desired.</p>
    <h6><a id="precognition-defensive"></a>Precognition, Defensive</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 1
    <p>Your awareness extends a fraction of a second into the future,
allowing
you to better evade an opponent&rsquo;s blows.</p>
    <p>You gain a +1 insight bonus to AC and on all saving throws. If
caught
in a situation where your Dexterity bonus isn&rsquo;t applied to your
Armor
Class, this bonus to AC and saving throws does not apply.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 3 additional power points you spend, the insight
bonus
gained increases by 1.</p>
    <p>2. If you spend 6 additional power points, you can manifest
this power
as a swift action.</p>
    <h6><a id="precognition-greater"></a>Precognition, Greater</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Seer 6
    <b>Display:</b> Auditory and visual
    <b>Manifesting Time:</b> 10 minutes
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 hour/level
    <b>Power Points:</b> 11
    <p>As <a href="#precognition" style={{
        "color": "rgb(87, 158, 182)"
      }}>precognition</a>,
except as noted here. </p>
    <p>You gain a +4 insight bonus instead of a +2 bonus.</p>
    <h6><a id="precognition-offensive"></a>Precognition, Offensive</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action; see text
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 1
    <p>Your awareness extends a fraction of a second into the future,
allowing
you to better land blows against your opponent. You gain a +1 insight
bonus on your attack rolls.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 3 additional power points you spend, the insight
bonus
gained on your attack rolls increases by 1.</p>
    <p>2. If you spend 6 additional power points, you can manifest
this power
as a swift action.</p>
    <h6><a id="prescience-offensive"></a>Prescience, Offensive</h6>
Clairsentience
    <b>Level:</b> Psion/wilder 1, psychic warrior 1
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level (D)
    <b>Power Points:</b> 1
    <p>Your awareness extends a fraction of a second into the future,
allowing
you to better aim blows against your opponent. You gain a +2 insight
bonus on your damage rolls.</p>
    <p><b>Augment:</b> You can augment this power in one or both of
the following
ways.</p>
    <p>1. For every 3 additional power points you spend, the insight
bonus
gained on your damage rolls increases by 1. </p>
    <p>2. If you spend 6 additional power points, you can manifest
this power
as a swift action.</p>
    <h6><a id="prevenom"></a>Prevenom</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Creation)</span>
    <b>Level:</b> Psychic warrior 1
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 min./level or until discharged
    <b>Power Points:</b> 1
    <p>If you have a claw attack (either from an actual natural
weapon or from
an effect such as <a href="psionicPowersAtoC.html#claws-of-the-beast" style={{
        "color": "rgb(87, 158, 182)"
      }}>claws of the beast</a>), you can use
this power to
produce a mild venom that coats one of your claws. On your next
successful melee attack, the venom deals 2 points of Constitution <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage</a>. A target struck by the
poison can make a Fortitude save (DC 10
+ 1/2 your manifester level + your key ability modifier) to negate the
damage.</p>
    <p><b>Augment:</b> For every 6 additional power points you spend,
this power&rsquo;s
Constitution damage increases by 2 points.</p>
    <h6><a id="prevenom-weapon"></a>Prevenom Weapon</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Creation)</span>
    <b>Level:</b> Psychic warrior 1
    <b>Range:</b> Touch
    <b>Target:</b> Weapon touched
    <b>Power Points:</b> 1
    <p>As <a href="#prevenom" style={{
        "color": "rgb(87, 158, 182)"
      }}>prevenom</a>,
except your weapon gains the poison coating as
long as it
remains in your grip.</p>
    <h6><a id="prowess"></a>Prowess</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Clairsentience</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 immediate action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 3
    <p>If an enemy provokes an attack of opportunity from you, you
can make
the attack even if you&rsquo;ve already taken your allotted number of
attacks
of opportunity this round (usually one).</p>
    <p>You can manifest this power instantly, quickly enough to gain
an extra
attack of opportunity in the same round. Manifesting this power is an
immediate action.</p>
    <h6><a id="psionic-blast"></a>Psionic Blast</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 3
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> 30 ft.
    <b>Area:</b> 30-ft. cone-shaped burst
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 5
    <p>The air ripples with the force of your mental attack, which
blasts the
minds of all creatures in range. Psionic blast <a href="abilitiesAndConditions.html#stunned" style={{
        "color": "rgb(87, 158, 182)"
      }}>stuns</a> all affected
creatures for 1 round.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
the duration of
the stun effect increases by 1 round.</p>
    <h6><a id="psionic-lions-charge"></a>Psionic Lion&rsquo;s Charge</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 swift action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> Instantaneous
    <b>Power Points:</b> 3
    <p>You gain the powerful charging ability of a lion. When you <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charge</a>,
you
can make a full attack in the same round.</p>
    <p>You can manifest this power with an instant thought, quickly
enough to
gain the benefit of the power as you charge. Manifesting the power is a
swift action, like manifesting a quickened power, and it counts toward
the normal limit of one quickened power per round. You cannot manifest
this power when it isn&rsquo;t your turn.</p>
    <p><b>Augment:</b> For every additional power point you spend,
each of your
attacks after a charge in the current round gains a circumstance bonus
on damage equal to the number of additional points spent.</p>
    <h6><a id="psionic-lock"></a>Psionic Lock</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychoportation</span>
    <b>Level:</b> Psion/wilder 2
    <b>Display:</b> Material
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Door, chest, or portal touched, up to 30 sq.
ft./level in size
    <b>Duration:</b> Permanent
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 3
    <p>A psionic lock manifested upon a door, chest, or portal
psionically
locks it. You can freely pass your own lock without affecting it;
otherwise, a door or object secured with psionic lock can be opened
only by breaking in or by a successful <a href="psionicPowersDtoF.html#dispel-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispel psionics</a> effect. Add +10
to the normal DC to break open a door or portal affected by this power.</p>
    <h6><a id="psionic-repair-damage"></a>Psionic Repair Damage</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Metacreativity</span>
    <b>Level:</b> Shaper 2
    <b>Display:</b> Material and visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Construct touched
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates (harmless)
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 3
    <p>When laying your hands upon a construct that has at least 1
hit point
remaining, you reknit its structure to repair damage it has taken. The
power repairs 3d8 points of damage +1 point per manifester level.
Constructs that are immune to psionics or magic cannot be repaired in
this fashion.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power
repairs an additional 1d8 points of damage.</p>
    <h6><a id="psionic-revivify"></a>Psionic Revivify</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism (Healing)
[Good]</span>
    <b>Level:</b> Egoist 5
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Dead creature touched
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> Yes (harmless)
    <b>Power Points:</b> 9, XP
    <p>Psionic revivify lets a manifester reconnect a corpse&rsquo;s
psyche
with its
body, restoring life to a recently deceased creature. The power must be
manifested within 1 round of the victim&rsquo;s death. Before the
psyche of
the deceased has completely left the body, this power halts its journey
while repairing somewhat the damage to the body.</p>
    <p>This power functions like the <a href="spellsPtoR.html#raise-dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>raise
dead</a> spell, except that the
affected
creature receives no level loss, no Constitution loss, and no loss of
powers. </p>
    <p>The creature has -1 hit points (but is stable) after being
restored to
life.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> 200 XP.</p>
    <p><b>Augment:</b> For every 100 additional experience points
that both you and
the subject pay, the manifestation of this power can be delayed by 1
additional round.</p>
    <h6><a id="psionic-scent"></a>Psionic Scent</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Psychic warrior 2
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level
    <b>Power Points:</b> 3
    <p>You gain an enhanced sense of smell, equivalent to the <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a>
ability of
some monsters. This ability allows you to detect approaching enemies,
sniff out hidden foes, and track by sense of smell. With the scent
ability, you can identify familiar odors just as humanoids do familiar
sights.</p>
    <p>You can detect opponents within 30 feet by sense of smell. If
the
opponent is upwind, the range increases to 60 feet; if downwind, it
drops to 15 feet. Strong scents, such as smoke or rotting garbage, can
be detected at twice the ranges noted above. Overpowering scents, such
as skunk musk or troglodyte stench, can be detected at triple normal
range.</p>
    <p>When you detect a scent, the exact location of the source is
not
revealed-only its presence somewhere within range. You can take a move
action to note the direction of the scent. Whenever you come within 5
feet of the source, you pinpoint the source&rsquo;s location.</p>
    <p>If you have the <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a> feat, you can follow
tracks by smell,
making a
Wisdom (or <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>) check to find or
follow a track. The typical DC
for a fresh trail is 10 (no matter what kind of surface holds the
scent). This DC increases or decreases depending on how strong the
quarry&rsquo;s odor is, the number of creatures, and the age of the
trail.
For each hour that the trail is cold, the DC increases by 2. The
ability otherwise follows the rules for the Track feat. If you are
tracking with psionic scent, you ignore the effects of surface
conditions and poor visibility.</p>
    <h6><a id="psychic-chirurgery"></a>Psychic Chirurgery</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Telepath 9
    <b>Display:</b> Auditory, mental, and visual
    <b>Manifesting Time:</b> 10 minutes
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 17, XP; see text
    <p>You can repair psychic damage or grant another creature
knowledge of
powers you know, depending on the version of this power you manifest.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Repair Psychic Damage:</span>
You can remove any compulsions and
charms
affecting the subject. In fact, you can remove any instantaneous or
permanent effect caused by a psychic power with psychic chirurgery.
Unlike with <a href="psionicPowersAtoC.html#aura-alteration" style={{
        "color": "rgb(87, 158, 182)"
      }}>aura alteration</a>, these effects
end or are negated as soon
as this power is manifested, with no need for another saving throw.</p>
    <p>You can remove all negative levels affecting the subject,
regardless of
how it lost those levels, restoring it to the highest level it had
previously attained. Also, you can restore levels lost to <a href="abilitiesAndConditions.html#energy-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>energy drain</a>
or a similar effect if the level drain occurred within a number of
hours equal to your manifester level.</p>
    <p>You can also remove all psionic effects penalizing the
subject&rsquo;s
ability scores, heal all <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, and remove any <a href="abilitiesAndConditions.html#ability-drained" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability drain</a>
affecting the subject. Psychic chirurgery negates all forms of
insanity, confusion, the effect of such powers as <a href="#microcosm" style={{
        "color": "rgb(87, 158, 182)"
      }}>microcosm</a>, and so on,
but it does not restore levels or Constitution points lost due to death.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Transfer Knowledge:</span>
If desired, you can use this power to
directly
transfer knowledge of a power you know to another psionic character.
You can give a character knowledge of a power of any level that she can
manifest, even if the power is not normally on the character&rsquo;s
power
list. Knowledge of powers gained through psychic chirurgery does not
count toward the maximum number of powers a character can know per
level.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> Each time
you use psychic chirurgery to implant knowledge of a
power in another creature, you pay an XP cost equal to 1,000 x the
level of the power implanted. If you and the subject are both willing
to do so, you can split this cost evenly.</p>
    <h6><a id="psychic-crush"></a>Psychic Crush</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 5
    <b>Display:</b> Auditory
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Close (25 ft. +5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Will partial; see text
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 9
    <p>Your will abruptly and brutally crushes the mental essence of
any one
creature, debilitating its acumen. The target must make a Will save
with a +4 bonus or collapse <a href="abilitiesAndConditions.html#unconscious" style={{
        "color": "rgb(87, 158, 182)"
      }}>unconscious</a> and <a href="abilitiesAndConditions.html#dying" style={{
        "color": "rgb(87, 158, 182)"
      }}>dying</a> at -1 hit points. If
the target succeeds on the save, it takes 3d6 points of damage.</p>
    <p><b>Augment:</b> For every 2 additional power points you spend,
this power&rsquo;s
damage increases by 1d6 points. </p>
    <h6><a id="psychic-reformation"></a>Psychic Reformation</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Telepathy [Mind-Affecting]</span>
    <b>Level:</b> Psion/wilder 4
    <b>Display:</b> Auditory, mental, and visual
    <b>Manifesting Time:</b> 10 minutes
    <b>Range:</b> Close (25 ft. + 5 ft./2 levels)
    <b>Target:</b> One creature
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> None
    <b>Power Resistance:</b> No
    <b>Power Points:</b> 7, XP; see text
    <p>When this power is manifested, the subject can choose to spend
its most
recently gained skill points differently (picking new skills and
abandoning old ones if it chooses) and to choose a different feat from
the one it selected when advancing from its previous level to its
current level.</p>
    <p>The subject can also choose to forget powers it acquired when
advancing
to its current level, replacing them with new ones.</p>
    <p>The subject can undo decisions of these sorts that were made
at lower
levels, if both the subject and the manifester agree to pay the
necessary XP before this power is manifested (see below). The subject
must abide by the standard rules for selecting skills and feats, and so
it cannot take feats for which it doesn&rsquo;t qualify or take
crossclass
skills as class skills.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>XP Cost:</span> This power
costs 50 XP to manifest to reformat choices made
when the character reached her current level. For each additional
previous level into which the revision reaches, the power costs an
additional 50 XP. The manifester and subject split all XP costs evenly.</p>
    <h6><a id="psychic-vampire"></a>Psychic Vampire</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 4, psychic warrior 4
    <b>Display:</b> Mental
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Touch
    <b>Target:</b> Creature touched
    <b>Duration:</b> Instantaneous
    <b>Saving Throw:</b> Fortitude negates
    <b>Power Resistance:</b> Yes
    <b>Power Points:</b> 7
    <p>This power shrouds your hand or a natural weapon you possess
with
darkness that you can use to drain an opponent&rsquo;s power.</p>
    <p>If you manifest this power to affect your hand, the next
successful
melee touch attack you make (if the victim fails its Fortitude save)
drains 2 power points from your foe for every manifester level you
have. The drained points simply dissipate. Your touch attack, charged
with psionic power, is treated as an armed attack.</p>
    <p>If you manifest this power to affect a natural weapon you
possess, you
must make a successful melee attack with the weapon to gain the
power&rsquo;s
benefit.</p>
    <p>Against a psionic being that has no power points or a
nonpsionic foe,
your attack instead deals 2 points of Intelligence, Wisdom, or Charisma
      <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage</a> (your choice).</p>
    <h6><a id="psychofeedback"></a>Psychofeedback</h6>
    <span style={{
      "fontStyle": "italic"
    }}>Psychometabolism</span>
    <b>Level:</b> Egoist 5, psychic warrior 5
    <b>Display:</b> Visual
    <b>Manifesting Time:</b> 1 standard action
    <b>Range:</b> Personal
    <b>Target:</b> You
    <b>Duration:</b> 1 round/level (D)
    <b>Power Points:</b> 9
    <p>You can readjust your body to boost one physical ability score
at the
expense of one or more other scores. Select one ability score you would
like to boost, and increase it by the same amount that you decrease one
or more other scores. All score decreases are treated as a special form
of <a href="abilitiesAndConditions.html#ability-damaged" style={{
        "color": "rgb(87, 158, 182)"
      }}>ability damage</a>, called ability
burn, which cannot be magically or
psionically healed - it goes away only through natural healing.</p>
    <p>You can boost your Strength, Dexterity or Constitution score
by an
amount equal to your manifester level (or any lesser amount), assuming
you can afford to burn your other ability scores to such an extent.</p>
    <p>When the duration of this power expires, your ability boost
also ends,
but your ability burn remains until it is healed naturally.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      